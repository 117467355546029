(function () {
    /**
     * ----------------------
     * ---- VUE COMPONENT ---
     * ----------------------
     *
     * - Premium modal
     * -- Modal for premium paywall
     *
     * Usages:
     *   - main.js
     *
     * Dependencies:
     *   - login-modal component
     */
    Vue.component("premium-modal", {
        props: [
            "isModalOpen",
            "isUserLoggedIn",
            "selectedFeature",
            "socialLoginState", // login-modal
            "recaptchaModule", // login-modal
            "socialLoginModule", // login-modal
            "i18n",
            "analyticsRef",
        ],
        data: function () {
            return {
                isLoginModalOpen: false, // login-modal
            };
        },
        template: `
      <!-- Premium Modal -->
      <div v-if="isModalOpen"
           class="premium-modal--mask"
           :aria-labelledby="i18n.txtPremiumFeature"
           aria-modal="true"
           ref="premiumModal"
           v-cloak>
        <div class="premium-modal__wrapper">
          <div class="premium-modal__content" id="premium-modal"
               ref="premiumModalContent">
            <div class="premium-modal__body">
              <div class="premium-modal__titles">
                <div class="premium-modal__title">
                  {{ i18n.txtPremiumFeature }}
                </div>
                <div class="premium-modal__subtitle-trial">
                  {{ i18n.txtTrial }}
                </div>
              </div>
              <hr v-if="!isFeatureAssetVideo">
              <div class="premium-modal__feature">
                <div class="premium-modal__feature-picture">
                  <img v-if="!isFeatureAssetVideo"
                       :style="defaultImgWidthOverride" :src="featureAsset">
                  <video v-if="isFeatureAssetVideo" autoplay loop muted
                         playsinline>
                    <source :src="featureAsset + '.webm'" type="video/webm">
                    <source :src="featureAsset + '.mp4'" type="video/mp4">
                    Your browser does not support the video tag.
                  </video>

                </div>
                <div class="premium-modal__feature-info">
                  <div class="premium-modal__feature-title">
                    {{ featureTitle }}
                  </div>
                  <div v-html="featureDesc"
                       class="premium-modal__feature-text"/>
                </div>
              </div>
            </div>
            <div class="premium-modal__footer">
              <div class="premium-modal__get-premium">
                <button class="btn btn-large premium-modal__get-premium-button"
                        @click="getPremium">
                  {{ i18n.txtWikilocPremium }}
                </button>
              </div>
              <div class="premium-modal__not-now">
                <button class="btn btn-large premium-modal__not-now-button"
                        @click="close">
                  {{ i18n.txtNotNow }}
                </button>
              </div>
            </div>
            <div v-if="!isUserLoggedIn || isUserLoggedIn == 'false'"
                 class="premium-modal__extra-footer">
              <div class="premium-modal__login">
                <button class="btn btn-large premium-modal__login-button"
                        @click="openLoginModal">
                  {{ i18n.txtModalPremiumLogin }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- FULLSCREEN MODALS -->
        <login-modal v-if="!isUserLoggedIn || isUserLoggedIn == 'false'"
                     :isModalOpen="isLoginModalOpen"
                     :state="socialLoginState"
                     :recaptchaModule="recaptchaModule"
                     :socialLoginModule="socialLoginModule"
                     :i18n="i18n"
                     @closeLoginModalEvent="closeLoginModal"
        >
        </login-modal>
      </div>
    `,
        methods: {
            getPremium: getPremium,
            onClickOutside: onClickOutside,
            close: close,
            openLoginModal: openLoginModal,
            closeLoginModal: closeLoginModal,
        },
        computed: {
            featureAsset: featureAsset,
            isFeatureAssetVideo: isFeatureAssetVideo,
            featureTitle: featureTitle,
            featureDesc: featureDesc,
            defaultImgWidthOverride: defaultImgWidthOverride,
        },
        watch: {
            isModalOpen: isModalOpen,
        },
        //VUE LIFECYCLE
        mounted: mounted,
        beforeDestroy: beforeDestroy,
    });

    /**
     * -----------------
     * ---- CONSTANTS --
     * -----------------
     */

    const FEATURE_ASSETS = _generateFeatureAssets();
    const IMAGE_ROOT_URL_ =
        "https://sc.wklcdn.com/wikiloc/assets/styles/images/premium/";
    const VIDEO_ROOT_URL_ = "https://sc.wklcdn.com/wikiloc/assets/videos/";
    const IMAGE_DEFAULT =
        "https://sc.wklcdn.com/wikiloc/images/apple-touch-icon.png";

    const FEATURE_TITLES = {}; // populated at _populateFeatureTitles() as 18n is required
    const FEATURE_DESCS = {}; // populated at _populateFeatureDocs() as 18n is required

    const PREMIUM_URL = "premium";

    /**
     * -----------------
     * ---- METHODS ----
     * -----------------
     * https://vuejs.org/v2/guide/instance.html#Data-and-Methods
     */
    function onClickOutside(event) {
        const el = this.$refs.premiumModalContent;
        if (el && !el.contains(event.target)) {
            this.close();
        }
    }

    function close() {
        this.$emit("closePremiumModalEvent", {});
    }

    function getPremium() {
        let premiumUrl = window.location.origin + "/" + PREMIUM_URL;
        if (this.analyticsRef) {
            premiumUrl += "?ref=" + this.analyticsRef;
        }
        window.location.href = premiumUrl;
    }

    function openLoginModal() {
        this.isLoginModalOpen = true;
    }

    function closeLoginModal() {
        this.isLoginModalOpen = false;
    }

    /**
     * ---- COMPUTED ATTRIBUTES ----
     * https://vuejs.org/v2/guide/computed.html#Computed-Properties
     */

    function featureAsset() {
        let asset = FEATURE_ASSETS[this.selectedFeature].filename;
        let rootURL = this.isFeatureAssetVideo
            ? VIDEO_ROOT_URL_
            : IMAGE_ROOT_URL_;
        return asset ? rootURL + asset : IMAGE_DEFAULT;
    }

    function isFeatureAssetVideo() {
        return FEATURE_ASSETS[this.selectedFeature].isVideo;
    }

    function featureTitle() {
        return FEATURE_TITLES[this.selectedFeature];
    }

    function featureDesc() {
        return FEATURE_DESCS[this.selectedFeature];
    }

    /**
     * If the default image is shown, apply a lower width
     */
    function defaultImgWidthOverride() {
        let style = {};
        if (this.featureAsset === IMAGE_DEFAULT) {
            style["width"] = "100px";
        }

        return style;
    }

    /**
     * ------------------
     * ---- WATCHERS ----
     * ------------------
     * When Vue reactivity is not enough
     * https://vuejs.org/v2/guide/computed.html#Watchers
     */
    function isModalOpen(newIsModalOpen, oldIsModalOpen) {
        _doubleRaf(() => {
            if (newIsModalOpen) {
                _addOnClickOutsideEvent(this);
                _enableDocumentScroll(false);
                if (newIsModalOpen && newIsModalOpen !== oldIsModalOpen) {
                    window.dataLayer = window.dataLayer || [];
                    if (this.analyticsRef) {
                        gtagEvent("view_promotion", {
                            ref: this.analyticsRef,
                        });
                    }
                }
            } else {
                _enableDocumentScroll(true);
            }
        });
    }

    /**
     * -----------------------
     * ---- VUE LIFECYCLE ----
     * -----------------------
     * https://vuejs.org/v2/guide/instance.html#Lifecycle-Diagram
     */

    /**
     * Called after the instance has been mounted, where el is replaced by the newly created vm.$el.
     * If the root instance is mounted to an in-document element, vm.$el will also be in-document when mounted is called.
     *
     * Note that mounted does not guarantee that all child components have also been mounted.
     * If you want to wait until the entire view has been rendered, you can use vm.$nextTick inside of mounted
     */

    function mounted() {
        this.$nextTick(function () {
            //Init
            //Prop to data initialization
            //Vue eventbus Listeners
            _populateFeatureTitles(this.i18n);
            _populateFeatureDescs(this.i18n);
        });
    }

    /**
     * Called right before a Vue instance is destroyed. At this stage the instance is still fully functional.
     */
    function beforeDestroy() {
        _rmOnClickOutsideEvent(this);
    }

    /**
     * -------------------------
     * ---- PRIVATE METHODS ----
     * -------------------------
     * Remember to use .call() if you need access to Vue scope!
     */

    function _generateFeatureAssets() {
        let featureAssets = {};

        featureAssets[GLOBAL_PREMIUM_FEATURES.SEARCH_PASSING_AREA] = {
            filename: "passing-area.png",
            isVideo: false,
        };
        featureAssets[GLOBAL_PREMIUM_FEATURES.SEND_TO_GPS] = {
            filename: "send-to-garmin.png",
            isVideo: false,
        };
        featureAssets[GLOBAL_PREMIUM_FEATURES.EXPLORE_USER] = {
            filename: "trail-list.png",
            isVideo: false,
        }; // == TRAIL_LIST_SEARCH
        featureAssets[GLOBAL_PREMIUM_FEATURES.ADVANCED_FILTERS] = {
            filename: "advanced-filter.png",
            isVideo: false,
        };
        featureAssets[GLOBAL_PREMIUM_FEATURES.CUSTOM_TRAIL_LISTS] = {
            filename: "favorite-list.png",
            isVideo: false,
        };
        featureAssets[GLOBAL_PREMIUM_FEATURES.MAP_3D] = {
            filename: "3d-maps-feature", //Using video we don't add extension because we both need .mp4 and .webm for wide browser support.
            isVideo: true,
        };
        featureAssets[GLOBAL_PREMIUM_FEATURES.ROUTE_PLANNER] = {
            filename: "route-planner.png",
            isVideo: false,
        };

        return featureAssets;
    }

    function _populateFeatureTitles(i18n) {
        FEATURE_TITLES[GLOBAL_PREMIUM_FEATURES.SEARCH_PASSING_AREA] =
            i18n["txtFeatureNameZdp"]; // MapAction
        FEATURE_TITLES[GLOBAL_PREMIUM_FEATURES.SEND_TO_GPS] =
            i18n["txtFeatureName"]; // DownloadAction
        FEATURE_TITLES[GLOBAL_PREMIUM_FEATURES.EXPLORE_USER] =
            i18n["txtFeatureName"]; // UserAction
        FEATURE_TITLES[GLOBAL_PREMIUM_FEATURES.ADVANCED_FILTERS] =
            i18n["txtFeatureNameAdvFilters"]; // MapAction
        FEATURE_TITLES[GLOBAL_PREMIUM_FEATURES.CUSTOM_TRAIL_LISTS] = i18n[
            "txtFeatureNameFavourite"
        ]
            ? i18n["txtFeatureNameFavourite"] // UserAction
            : i18n["txtFeatureName"]; // ViewAction
        FEATURE_TITLES[GLOBAL_PREMIUM_FEATURES.MAP_3D] =
            i18n["txtFeatureName3Dmap"];
        FEATURE_TITLES[GLOBAL_PREMIUM_FEATURES.ROUTE_PLANNER] =
            i18n["txtFeatureName"];
    }

    function _populateFeatureDescs(i18n) {
        FEATURE_DESCS[GLOBAL_PREMIUM_FEATURES.SEARCH_PASSING_AREA] =
            i18n.txtFeatureDescriptionZdp; // MapAction
        FEATURE_DESCS[GLOBAL_PREMIUM_FEATURES.SEND_TO_GPS] =
            i18n.txtFeatureDescription; // DownloadAction
        FEATURE_DESCS[GLOBAL_PREMIUM_FEATURES.EXPLORE_USER] =
            i18n.txtFeatureDescription; // UserAction / == TRAIL_LIST_SEARCH
        FEATURE_DESCS[GLOBAL_PREMIUM_FEATURES.ADVANCED_FILTERS] =
            i18n.txtFeatureDescriptionAdvFilters; // MapAction
        FEATURE_DESCS[GLOBAL_PREMIUM_FEATURES.CUSTOM_TRAIL_LISTS] =
            i18n.txtFeatureDescriptionFavourite
                ? i18n.txtFeatureDescriptionFavourite // UserAction
                : i18n.txtFeatureDescription; // ViewAction
        FEATURE_DESCS[GLOBAL_PREMIUM_FEATURES.MAP_3D] =
            i18n.txtFeatureDescription3Dmap;
        FEATURE_DESCS[GLOBAL_PREMIUM_FEATURES.ROUTE_PLANNER] =
            i18n.txtFeatureDescription; // UserAction / == USER_SEARCH
    }

    /**
     * EXPERT TIP.
     * Wait the double of frames than vue.nextTick()
     * Sometimes the computation is much bigger than what nextTick can handle.
     * We need to wait the double of frames: https://github.com/vuejs/vue/issues/9200#issuecomment-468512304
     * @param callback
     */
    function _doubleRaf(callback) {
        requestAnimationFrame(() => {
            requestAnimationFrame(callback);
        });
    }

    /**
     * Enable or disable global document scroll.
     *
     * WARN: It breaks the encapsulation of the component, but it is the only way
     * to block the scroll for the modals
     *
     * @param enable: true/false to enable or disable the document scroll
     */
    function _enableDocumentScroll(enable) {
        let value = enable ? "auto" : "hidden";

        document.body.style.overflow = value;
    }

    function _addOnClickOutsideEvent(vueInstance) {
        vueInstance.$refs.premiumModal.addEventListener(
            "click",
            vueInstance.onClickOutside
        );
    }

    function _rmOnClickOutsideEvent(vueInstance) {
        if (vueInstance.$refs.premiumModal) {
            vueInstance.$refs.premiumModal.removeEventListener(
                "click",
                vueInstance.onClickOutside
            );
        }
    }

    /**
     * --------------
     * ---- DTOs ----
     * --------------
     *
     */
})();
